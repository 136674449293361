<template>
  <div :class="$style.focusWrapper">
    <div :class="$style.logoTop">
      <CommonLogo @click="navigateTo('/')"/>
    </div>
    <slot/>
  </div>
</template>
<style module lang="scss">
.focusWrapper {
  width: 100%;
  max-width: 460px;
  margin: 10px auto;
}

.logoTop {
  text-align: center;
  margin: 10px 0;
}
</style>
